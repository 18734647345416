import React from 'react'
import Layout from '@compositions/Layout'

const breadcrumbs = [{ key: 'search', text: 'Search', url: '/search' }]

const Search = () => (
  <Layout breadcrumbs={breadcrumbs}>
    <h1>Search Page</h1>
    <p>Lorem ipsum dolor sit amet consectetum...</p>
  </Layout>
)

export default Search
